@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.confirmWrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
}

.confirmTitle {
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  font-size: 18px;
  margin: 0;
}

.confirmButtons {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  
  @include breakpoint(400px) {
    gap: 12px;
    grid-template-columns: 1fr;
  }
}

.confirmButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 7px 16px 4px 16px;
  font-weight: 500;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;

  &[disabled] {
    cursor: default;
    background-color: lightgrey;
    border: grey;
    color: black;

    &:hover {
      background-color: rgb(179, 179, 179);
      border: grey;
      color: black;
    }
  }
}

.confirmButtonYes {
  background: #EA2727;
  color: white;
  border: 2px solid #EA2727;
  outline: none;

  &:hover {
    background-color: white;
    color: #EA2727;
  }

  &:focus {
    border-color: #981919;
  }
}

.confirmButtonNo {
  background: white;
  color: black;
  border: 2px solid black;
  // outline: none;

  &:hover {
    background-color: black;
    color: white;
  }
}