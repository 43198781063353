@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
@keyframes skeleton-loading {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideMask {
  0% {
    mask-position: 0%;
  }
  100% {
    mask-position: 600%;
  }

}

.weekTableLoading{
  mask-image: linear-gradient(45deg, #000 25%, rgba(0, 0, 0, .2) 50%, #000 75%);
  mask-size: 120%;
  mask-position: 0%;
  animation: slideMask 2s linear infinite;


  > div {
    animation: skeleton-loading 1s linear infinite alternate-reverse;
  }
}

.loadingMockupText {
  opacity: 0;
}

.weekTableContentDroppable {
  transition: background-color 0.25s cubic-bezier(0.3, 0, 0.745, 0.715);
  height: 100%;
}

.weekTableContentDroppableOver {
  background: #f1f1fd;
}

.weekTableContentDraggable {
  transition: background-color 0.25s cubic-bezier(0.3, 0, 0.745, 0.715);
  background-color: white;
}

.weekTableContentDraggableDragging {
  background: #E5E5FF;
  border-radius: 4px;
}

// old way
// .onDragOver {
//   box-shadow: 0 0 0 2px #9e9ef2;
//   border: 1px solid #E5E5FF!important;
  
//   > div:is(:last-of-type) {
//     box-shadow: inset 0px 0px 10px 3px rgb(229, 229, 255);
//   }
// }

.weekTable {
  border-radius: 5px;
  padding: 2px 2px 16px 2px;
  // padding-bottom: 16px;
  overflow-x: auto;

  @include breakpoint(640px) {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 0px;
  }

  &:hover {
    &::-webkit-scrollbar {

    }
  
    &::-webkit-scrollbar-track {
      background: #E5E5FF33;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #E5E5FF;

      &:hover {
        background: #cdcdfc;
      }
    }
  }

  .weekTableBody {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 16px;
    width: calc(140% + 6px);
    
    @include breakpoint(1280px) {
      width: calc(175% + 6px);
    }
    
    @include breakpoint(1024px) {
      width: calc(235% + 6px);
    }
    
    @include breakpoint(800px) {
      width: calc(355% + 6px);
    }
    
    @include breakpoint(640px) {
      width: calc(490% + 6px);
    }
  }

  .addBtn {
    background: #5626F7;
    color: white;
    border: none;
    box-shadow: none;
    outline: none;
    gap: 8px;
    padding: 5px 8px 3px 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;

    &:hover {
      background: #3F17C3;
    }

     &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #9e9ef2;
    }

    span {
      @include breakpoint(640px) {
        display: none;
      }
    }

    &.addBtnMockup {
      cursor: default;
    }
  }

  .dayName {
    margin: 11px 0 0 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    align-self: flex-start;

    @include breakpoint(1500px) {
      font-size: 15px;
    }
  }

  .dayDate {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    align-self: flex-end;

    @include breakpoint(1500px) {
      font-size: 14px;
    }
  }

  .totalTime {
    font-size: 14px;
    line-height: 1.2;
    border-radius: 4px;
    padding: 5px 8px 4px 8px;
    background: #F8F8FE80;

    span {
      @include breakpoint(640px) {
        font-size: 0.8em;
      }
    }
  }

  .weekTableHead {
    display: grid;
    gap: 8px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    padding: 10px;
    background: #E5E5FF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @include breakpoint(640px) {
      gap: 6px;
      padding: 6px;
    }
  }

  .todayStyle .weekTableHead {
    background: #acacf5;
  }

  .weekTableHeadAddWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .totalTimeWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .weekTableBodyCol {
    position: relative;
    border: 1px solid #E5E5FF;
    border-radius: 8px;
    background: white;
    display: grid;
    gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 105px 1fr;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include breakpoint(1500px) {
      grid-template-rows: 95px 1fr;
    }

    @include breakpoint(640px) {
      grid-template-rows: 80px 1fr;
    }
  }

  .todayStyle.weekTableBodyCol {
    border-color: #acacf5;
  }
}

.weekTableContent {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 300px;
  height: calc(100vh - 430px);
  max-height: 600px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #FDFDFE;

  &::-webkit-scrollbar {
    height: 6px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 0px;
  }

  &:hover {
    &::-webkit-scrollbar {

    }
  
    &::-webkit-scrollbar-track {
      background: #E5E5FF33;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #E5E5FF;

      &:hover {
        background: #cdcdfc;
      }
    }
  }

  > div {
    &:not(:last-of-type) {
      margin-bottom: 32px;
      // padding-bottom: 16px;
      position: relative;

      @include breakpoint(640px) {
        margin-bottom: 16px;
        padding-bottom: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -16px;
        border-bottom: 1px solid #E5E5FF;
        pointer-events: none;

        @include breakpoint(640px) {
          bottom: -8px;
        }
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  .weekTableFakeItem {
    background: #f3f3ff;
    height: 20%;
    margin: 0 auto;
    display: block;
    width: calc(100% - 32px);

    &:first-of-type {
      margin-top: 16px;
    }
  }
}