@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.weekTotal {
    background: #EEEEFE;
    padding: 8px 16px;
    display: inline-flex;
    border-radius: 8px;
    line-height: 1;
    
    @include breakpoint(1024px) {
        font-size: 15px;
        padding: 8px 12px;
    }
}