@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.isDragging {
  background: #f0f0ff;
  box-shadow: 0 1px 1px 1px #f0f0ff;

  &:after {
    display: none;
  }
}

.dropBefore, .dropAfter {
  font-size: 10px;
  text-align: center;
  padding: 0;
  height: 50px;
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  font-size: 0;
  opacity: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    border: 1px dashed #d5d5f6;
    background: #f1f1ff;
    border-radius: 6px;
  }

  &.onDragOverOrder {
    opacity: 1!important;
    height: 150px!important;
    position: relative!important;
    inset: auto!important;
  }
}

.dropBefore {
  top: -8px;
}

.dropAfter {
  top: calc(100% - 32px);
}

.entryWrapper {
  position: relative;
  
  &:is(:last-child){
    height: 100%;

    .dropAfter {
      top: 135px;
      min-height: calc(100% - 135px);
    }
  }
}

.entry {
  position: relative;
  display: grid;
  gap: 16px;
  padding: 16px;
  // cursor: grab;

  @include breakpoint(1500px) {
    gap: 12px;
  }

  @include breakpoint(640px) {
    gap: 10px;
    padding: 8px 6px;
  }

  .entryTop {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
  }

  .entryTopIcons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  .entryBottom {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  .entryBottomRight {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    @include breakpoint(1500px) {
      gap: 6px;
    }
  }

  .entryTitle {
    margin: 0;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    
    @include breakpoint(1500px) {
      font-size: 14px;
    }
  }

  .descriptionBtn,
  .btnRemove {
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .btnRemove {
    position: relative;
    top: -2px;
    right: -3px;
    margin-bottom: -4px;
    border-radius: 4px;
    width: 24px;
    height: 24px;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #9e9ef2;
    }
  }

  .entryUrl {
    display: block;
    text-underline-offset: 0.2em;
    text-decoration-thickness: 1px;
    text-decoration-color: lightgrey;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #9e9ef2;
    }

    &:hover {
      text-decoration-color: inherit;
    }
  }

  .input {
    border: 1px solid #E5E5FF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    gap: 6px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.2;
    background: white;
    min-height: 34px;
    background: white;
    transition: border-color 0.1s ease-in;
    cursor: pointer;

    &.inputTime {
      gap: 0;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #9e9ef2;
    }

    &[disabled] {
      border-color: lightgray;
      cursor: default;

      span {
        opacity: 0.5!important;
      }
    }

    @include breakpoint(1500px) {
      font-size: 15px;
    }

    @include breakpoint(640px) {
      font-size: 13px;
      padding: 4px 6px;
    }
  
    &:hover {
      border: 1px solid #c0c0f5;
    }

    .desktopOnlyText {
      @include breakpoint(1500px) {
        display: none;
      }
    }
  }
}