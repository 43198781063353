@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.brandBadge {
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  padding: 7px 7px 6px 7px;
  border-radius: 4px;
}
