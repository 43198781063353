@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.reportsPanel {
  //background: #EEEEFE;
  //padding: 8px 16px;
  display: flex;
  column-gap: 8px;
  border-radius: 8px;
  line-height: 1;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  .multipleBtns {
    display: flex;
    column-gap: 8px;
  }

  .generateBtn {
    border: 2px solid black;
    color: black;
    background: transparent;
    box-shadow: none;
    outline: none;
    gap: 8px;
    padding: 5px 8px 3px 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;

    &:hover {
      background: black;
      color: white;
    }
  }

  @include breakpoint(1024px) {
    font-size: 15px;
    padding: 8px 12px;
  }
}