@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.popupOverlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 6000;

  @include breakpoint(md) {
    padding: 0 8px;
  }

  @include breakpoint(sm) {
    // height: auto;
    // align-items: flex-start;
  }

  &.visible {
    display: flex;
    background: rgba($white, 0.8);
  }

  &.dingDongOverlay {
    &.visible {
      background: rgba($black, 0.5);
    }
  }
}

.popupBody {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(28, 28, 28, 0.1);
  background: rgba(255, 255, 255, 0.98);
  // background: $white;
  border-radius: 6px;
  width: 812px;
  padding: 72px 110px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoint(sm) {
    padding: 24px 24px 80px;

    align-items: flex-start;
  }

  .dingDongOverlay & {
    width: 100%;
    max-width: 680px;
    padding: 50px 24px 24px 24px;
  }
}

.popupClose {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  top: 16px;
  right: 16px;
  border: 1px solid rgba(28, 28, 28, 0.3);
  border-radius: 2px;
  z-index: 6001;
  background: transparent;
  cursor: pointer;

  &:before {
    content: '';
    inset: -2px;
    position: absolute;
  }

  &:hover {
    opacity: 0.7;
  }

  .dingDongOverlay & {
    border: none;
    right: 20px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #9e9ef2;
    }
  }
}

// Popup with video variant
.popupBody.video {
  padding: 0;
  background-color: transparent;
  overflow-y: visible;
  overflow-x: visible;
  border: none;
  width: auto;

  .popupClose {
    right: -32px;
    top: 0;

    @include breakpoint(md) {
      top: -32px;
      right: 0;
    }
  }
}

.popupContent {
  .dingDongOverlay & {
    width: 100%;
  }
}

.popupBody.vertical {
  .popupContent {
    width: calc(56vh - #{calc(0.56 * (16px + $header-height-desktop + 16px))});
    height: calc(100vh - #{calc(16px + $header-height-desktop + 16px)});
    max-width: 100%;

    > * {
      border-radius: 6px;
      overflow: hidden;
    }

    @include breakpoint(md) {
      width: calc(56vh - #{calc(0.56 * (48px + $header-height-mobile + 16px))});
      max-width: calc(100vw - 32px);
      height: calc(100vh - #{calc(48px + $header-height-mobile + 16px)});
    }
  }
}
.popupBody.video.horizontal {
  .popupContent {
    max-width: 800px;
    width: calc(100vw - 16px - 48px);
    height: calc(100vh - #{calc(16px + $header-height-desktop + 16px)});
    max-height: calc(100vh - #{calc(16px + $header-height-desktop + 16px)});
    // overflow-y: scroll;
    border-radius: 6px;

    > * {
      border-radius: 6px;
      overflow: hidden;
    }

    @include breakpoint(md) {
      width: calc(100vw - 16px - 16px);
      height: calc(100vh - #{calc(48px + $header-height-mobile + 16px)});
      max-height: calc(100vh - #{calc(48px + $header-height-mobile + 16px)});
    }
  }
}

.popupOverlay.video {
  width: 100vw;
  height: 100vh;
  padding-left: 16px;
  padding-right: 48px;
  padding-bottom: 16px;
  padding-top: calc(16px + $header-height-desktop);

  @include breakpoint(md) {
    padding-right: 16px;
    padding-top: calc(48px + $header-height-mobile);
  }
}
