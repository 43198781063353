@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.timeInput {
  display: flex;
  gap: 2px;
  padding: 0;
  margin: 0;
  border: 1px solid #e5e5ff;
  border-radius: 4px;
  background: transparent
    url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_6_5810)"><path d="M11 18.4168C14.5439 18.4168 17.4167 15.544 17.4167 12.0002C17.4167 8.45634 14.5439 5.5835 11 5.5835C7.45621 5.5835 4.58337 8.45634 4.58337 12.0002C4.58337 15.544 7.45621 18.4168 11 18.4168Z" stroke="%231C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 8.5V12" stroke="%231C1C1C" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 12L13.3333 14.3333" stroke="%231C1C1C" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_6_5810"><rect width="14" height="14" fill="white" transform="translate(4 5)"/></clipPath></defs></svg>')
    no-repeat right 12px center;

  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 2px #9e9ef2;
  }

  &.small {
    background-position: right 1px center;
  }

  .timeInputInput {
    padding: 8px 0 8px 0;
    margin: 0;
    appearance: none;
    border: none;
    box-shadow: none;
    outline: none;
    width: 36px;
    background: transparent;
    line-height: 1.2;

    &:first-of-type {
      padding-left: 16px;
    }

    &:last-of-type {
      margin-right: 32px;
    }

    &.small {
      padding: 4px 4px;
      font-size: 15px;
      width: 26px;

      &:first-of-type {
        padding-left: 5px;
        padding-right: 2px;
      }
  
      &:last-of-type {
        padding-left: 2px;
        margin-right: 26px;
      }
    }
  }
}

.inputsTimeBetween {
  align-self: center;
}